import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import useStyles from './styles';

export default function Item(props) {
    
    const classes = useStyles();
    const theme = useTheme();
    const { Icon, title, subtitle, desc } = props;
    
    return(
        <Box className={classes.general} variant='outlined'>
            <Box className={classes.container}>
                
                <Avatar sx={{ width: 70, height: 70, backgroundColor: theme.colors.gray }} >
                    <Icon fontSize='large' color={theme.colors.white} />
                </Avatar>
                <Typography
                        variant='h5'
                        component='h2'
                        align='center'
                        sx={{ width: '100%', flexGrow: 1, paddingTop: '1em', fontWeight: 'bold' }}
                >
                    {title}
                </Typography>
                <Typography
                        variant='body1'
                        component='p'
                        align='center'
                        color='primary'
                        sx={{ width: '100%', flexGrow: 1, fontWeight: 'bold' }}
                >
                    {subtitle}
                </Typography>
                <Typography
                        variant='body1'
                        component='div'
                        align='center'
                        sx={{ width: '100%', flexGrow: 1, paddingTop: '0.2em' }}
                >
                    {desc}
                </Typography>

            </Box>
        </Box>
    );

}