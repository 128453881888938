import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector,
    TimelineContent, TimelineDot } from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useTheme } from '@mui/styles';
    
import { ConnectWithoutContactRounded, VoiceChatRounded, CoPresentRounded,
        Diversity3Rounded, EqualizerRounded} from '@mui/icons-material';    

import Title from '../../../components/Title';
import CTAScroll from '../../../components/CTAScroll';
import ViewPort from '../../../services/ViewPort';

import useStyles from './styles';


export default function Walkthrough(props) {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    const itens = [
        {
            title: 'Contato Inicial',
            phrase: 'Primeiros Passos',
            description: <div>
                <p style={{ padding: 0, margin: 0 }}>
                    Tudo começa com o seu contato pelo formulário do site ou WhatsApp.
                    Assim iniciamos a troca das primeiras informações sobre o negócio e suas dificuldades.    
                </p>                
            </div>,
            icon: <ConnectWithoutContactRounded />,
        },{
            title: 'Primeira Reunião',
            phrase: 'Entendendo o Cenário',
            description: <div>
                <p style={{ padding: 0, margin: 0 }}>
                    Realizaremos uma reunião online de 40min para explicar detalhadamente como nossa consultoria
                    funciona e entender melhor o cenário atual da sua empresa.<br />
                    A partir dessas informações, elaboraremos um projeto de consultoria sob medida.
                </p>                
            </div>,
            icon: <VoiceChatRounded />,
        },{
            title: 'Proposta Comercial',
            phrase: 'Apresentação do Projeto',
            description: <div>
                <p style={{ padding: 0, margin: 0 }}>
                    Detalhamos o projeto de consultoria, incluindo todas as etapas, os métodos de trabalho,
                    os resultados esperados, responsabilidades e o valor do projeto.
                </p>                
            </div>,
            icon: <CoPresentRounded />,
        },{
            title: 'Projeto',
            phrase: 'Trabalhando em Conjunto',
            description: <div>
                <p style={{ padding: 0, margin: 0 }}>
                    Dividido em fases, o projeto de consultoria envolve entregas constantes e colaboração
                    contínua entre consultor e cliente para abordar e solucionar os desafios identificados ao longo do processo.
                </p>                
            </div>,
            icon: <Diversity3Rounded />,
        },{
            title: 'Entrega Final',
            phrase: 'Conclusão do Projeto',
            description: <div>
                <p style={{ padding: 0, margin: 0 }}>
                    Apresentamos o relatório final com todas as ações realizadas, análises e discussões, para
                    que o empreendedor possa continuar administrando sua empresa com sucesso.
                </p>                
            </div>,
            icon: <EqualizerRounded />,
        },
    ];
    
    return(
        <Box className={classes.general}>
            
            <Box className={classes.container} >
                <Title
                        main='COMO FUNCIONA'
                        description='Etapas para transformar seu negócio'
                >
                    <p>
                        Entenda o passo a passo para o <b>diagnóstico</b> da sua empresa, definição do
                        <b> projeto</b> de consultoria e fortalecimento do seu negócio, garantindo <b>resultados</b> sustentáveis.
                    </p>
                </Title>

                <Timeline
                    position={width>sm? 'alternate-reverse' : 'right'}
                    sx={width>sm? null : {
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                >

                    {itens.map((e,i) => 
                        <TimelineItem key={i}>

                            <TimelineSeparator>
                                <TimelineDot color='primary'>
                                    {e.icon}
                                </TimelineDot>
                                {i < itens.length-1? <TimelineConnector /> : null}
                            </TimelineSeparator>
                            
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Card variant='outlined'>
                                    <CardContent>
                                        <Typography variant='h6' component='p' color={theme.colors.dark_gray}>
                                            {e.title}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} variant='overline' component='p' color={theme.colors.middle_gray}>
                                            {e.phrase}
                                        </Typography>
                                        <Typography variant='body1' component='p'>
                                            {e.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </TimelineContent>

                        </TimelineItem>
                    )}

                </Timeline>

                <Typography
                    variant='body1'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ padding: width>sm? '2.5em 8em 1em 8em' : '1em 0.2em 1em 0.2em' }}
                    // gutterBottom
                >
                    <p>
                        Um <u>diagnóstico especializado e preciso</u> pode fazer toda a diferença no crescimento e na estabilidade do seu negócio.<br />
                        Vamos trabalhar a quatro mãos para identificar os <u>verdadeiros desafios</u> e encontrar as <u>melhores soluções</u> para sua empresa.
                    </p>
                </Typography>

                <CTAScroll
                    main='ENTRE EM CONTATO'
                    secondary='ENTRE EM CONTATO'
                    text_value='E solicite um orçamento'
                    author={true}
                    warranties={[
                        '15 anos de experiência em consultoria empresarial',
                        'Soluções práticas para a realidade do seu negócio',
                        'Consultoria personalizada',
                        'Online e presencial'
                    ]}
                    // href='/proposal'
                    func={props.scrollToProposal}
                >
                    {/* <p style={{ padding:0, margin: 0}}>
                        Vamos conversar e analisar o seu caso <u>juntos</u>! Assim poderemos construir um projeto
                        à quatro mãos, que melhor se adeque a <u>sua realidade e empresa</u>.
                    </p> */}
                </CTAScroll>
            </Box>

        </Box>
    );

}