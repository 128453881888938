import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import useStyles from './styles';


export default function Pilar(props) {

    const classes = useStyles();
    const theme = useTheme();

    const { title, description, project, itens, Icon } = props;
    
    
    return(
        <Card className={classes.general} variant='contained' >
            <CardContent className={classes.content} >
                <Icon color='primary' fontSize='large' sx={{ mr: '0.5em' }} />
                <Box display='block' justifyContent='center' alignItems='center'>
                    <Box
                            display='flex'
                            alignItems='center'
                    >
                        <Typography
                            variant='h6'
                            component='p'
                            align='left'
                            display='flex'
                            color={theme.colors.dark_gray}
                            sx={{ fontWeight: 'bold' }}
                            // gutterBottom
                        >
                            {title}
                        </Typography>
                    </Box>
                    
                    <Typography
                        variant='body1'
                        component='p'
                        align='left'
                        color={theme.colors.gray}
                        // sx={{ fontStyle: 'italic' }}
                        gutterBottom
                    >
                        {description}
                    </Typography>
                    <Typography
                        variant='body1'
                        component='p'
                        align='left'
                        // color={theme.colors.gray}
                        // gutterBottom
                        sx={{ marginBottom: '0.5em' }}
                    >
                        {project}
                    </Typography>

                    <Box display='flex' justifyContent='center' flexDirection='column'>
                        {itens.map((a, i) => 
                            <Typography
                                variant='body1'
                                component='p'
                                align='left'
                                noWrap={false}
                                color={theme.colors.dark_gray}
                                sx={{ fontStyle: 'italic' }}
                                key={i}
                            >
                                - {a}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );

}