import { createTheme } from '@mui/material/styles';

const theme = createTheme({

    typography: {
        fontFamily: 'Inter, sans-serif',
    },

    palette: {
        primary: {
            main: '#09C1DC',
            contrastText: '#F2F2F2'
        },
        secondary: {
            main: '#2F96A2',
            contrastText: '#F2F2F2'
        },
    },

    colors: {
        light_blue: '#81D5DE',
        white_ish: '#D7EDEF',
        white: '#FFFFFF',
        very_light_gray: '#FDFDFD',
        light_gray: '#F1F1F1',
        middle_gray: '#6A6A6A',
        gray: '#8C8C8C',
        dark_gray: '#2A323A',
    },

});

export default theme;