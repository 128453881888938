import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        //margin: '2px',
        position: 'fixed',
        bottom: '20px',
        right: '20px',

        zIndex: '999',

        [theme.breakpoints.up('sm')]: {
            bottom: '25px',
            right: '25px',

            [theme.breakpoints.up('md')]: {
                bottom: '35px',
                right: '35px',
            }
        }
    },

    icon: {
        width: '50px!important',
        height: '50px!important',
        backgroundColor: '#25d366',
        color: '#FFF',
        borderRadius: '30px',
        textAlign: 'center',
        //fontSize: '20px',
        padding: '4px',
        boxShadow: '1px 1px 1px #999',

        [theme.breakpoints.up('md')]: {
            width: '60px!important',
            height: '60px!important',
        }
    },

    
}));
export default useStyles;