import React, { Fragment } from 'react';
import { useTheme } from '@mui/styles';
import { Typography, Box, Button, Avatar } from '@mui/material';
import { CheckCircleRounded } from '@mui/icons-material';

import alex_circle from '../../assets/alex/alex_circle.webp';

import ViewPort from '../../services/ViewPort';
import useStyles from './styles';


export default function CTAScroll(props) {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    const { main, secondary, text_value, warranties, author, func } = props;

    return (
        <Box className={classes.general} >

            <Button
                variant='contained'
                // endIcon={<PermPhoneMsgRounded />}
                sx={width>sm?
                    {
                        marginBottom: '0.1em',
                        paddingInline: '1em',
                        fontSize: '30px',
                        fontStyle: 'bold',
                    }
                :
                    {
                        marginBottom: '0.1em',
                        // marginInline: '2em',
                        fontSize: '16px',
                        fontStyle: 'bold',
                    }
                }
                onClick={func}
                fullWidth={width>sm?false:true}
            >
                {width>sm? main: secondary}
            </Button>

            <Typography
                variant='button'
                component='p'
                align='center'
                color={theme.colors.middle_gray}
                sx={{ marginBottom: '0.8em', fontWeight: 'bold' }}
                // gutterBottom
            >
                {text_value}
            </Typography>
            
            <Typography
                variant='body1'
                component='div'
                align='center'
                color={theme.colors.middle_gray}
                sx={{ marginBottom: '0.6em', maxWidth: '800px' }}
                // gutterBottom
            >
                {props.children}
            </Typography>

            {author?
                <Box className={classes.author}>
                    <Avatar
                            alt='consultor_alex'
                            src={alex_circle}
                            sx={{ width: 50, height: 50 }}
                    />
                    <Box sx={{ ml: '0.5em'}}>
                        <Typography
                                variant='h6'
                                component='p'
                                align='left'
                                color={theme.colors.dark_gray}
                                // sx={{ fontWeight: 'bold' }}
                        >
                            Alex Watanabe
                        </Typography>
                        <Typography
                                variant='body2'
                                component='p'
                                align='left'
                                color={theme.colors.middle_gray}
                                sx={{ fontStyle: 'italic' }}
                        >
                            Consultor Empresarial
                        </Typography>
                    </Box>
                </Box>
            :
                <Fragment/>
            }
            

            {/*
            04) GARANTIA
            30 dias de garantia?
            Cancela a hora que quiser…
            Paga somente após o primeiro mês se estiver satisfeito… caso contrário pode cancelar sem pagar nada…
            “Acesso imediato…”
            */}
            <Box className={classes.warranties}>
                {warranties.map((a, i) => 
                    <Box className={classes.warranty} key={i}>
                        <CheckCircleRounded color='secondary' style={{ marginInline: '0.2em' }} />
                        <Typography
                            variant='body1'
                            component='p'
                            align='left'
                            noWrap={false}
                            color={theme.colors.dark_gray}
                        >
                            {a}
                        </Typography>
                    </Box>
                )}
            </Box>

        </Box>
    );
}