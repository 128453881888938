import React, { useState, useEffect } from 'react';
import { Link, Tooltip, Typography } from '@mui/material';

import useStyles from './styles';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function WhatsApp(props) {
    const classes = useStyles();
    
    const { msg, popup_msg, time_between, time_up } = props;

    const [popup, setPopup] = useState(false);

    useEffect(() => {

        if (!popup) {
            setTimeout(() => {
                setPopup(true);
            },time_between);
        } else {
            setTimeout(() => {
                setPopup(false);
            },time_up);
        }
        
    }, [popup]);

    return (
        <Link
            href={'https://api.whatsapp.com/send?phone=551132800204&text='+msg}
            target='_blank'
            className={classes.general}
            id='whatsapp_link_id'
        >
            <Tooltip
                title={
                    <Typography
                        variant='body1'
                        component='p'
                        align='center'
                        sx={{ margin: 0, padding: '0.2em' }}
                        // gutterBottom
                    >
                        {popup_msg}
                    </Typography>
                }
                placement='top-end'
                arrow
                // onClose={handleTooltipClose}
                open={popup}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <WhatsAppIcon className={classes.icon} />
            </Tooltip>
        </Link>
    );

}