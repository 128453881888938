import React from 'react';
import { Box } from '@mui/material';
// import { useTheme } from '@mui/styles';

import Title from '../../../components/Title';
import CTAScroll from '../../../components/CTAScroll';

import useStyles from './styles';
    
export default function Benefits(props) {

    const classes = useStyles();
    // const theme = useTheme();

    return(
        <Box className={classes.general}>
            <Box className={classes.container} >
                <Title
                        main='E AÍ?'
                        description='Vamos mudar o rumo do seu de negócio?'
                >
                    Tudo começa com um clique e uma conversa pra gente se conhecer!
                </Title>

                <CTAScroll
                    main='ENTRAR EM CONTATO'
                    secondary='ENTRAR EM CONTATO'
                    text_value=''
                    author={true}
                    warranties={[
                        'Consultoria personalizada e individual',
                        'Soluções práticas para a realidade do seu negócio',
                        'Flexibilidade e conveniência',
                        'Online ou presencial',
                    ]}
                    // href='/proposal'
                    func={props.scrollToProposal}
                >
                    <p style={{ padding:0, margin: 0}}>
                        O <b>primeiro passo</b> para impulsionar sua empresa e<br />obter grandes resultados é aqui!
                    </p>
                </CTAScroll>
            </Box>
        </Box>
    );

}