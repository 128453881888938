import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import ReactPlayer from 'react-player';

import ViewPort from '../../../services/ViewPort';

import logo from '../../../assets/company/logo_completo.svg';

import useStyles from './styles';

export default function Welcome() {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    // const sm = 600;
    const md = 960;


    return(
        <Box className={classes.general}>
            <Box className={classes.container}>

                <img className={classes.logo} alt='nosso_consultor' src={logo}/>
                
                <Typography
                    variant={width > md? 'h4': 'h5'}
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ marginBottom: '0.5em', fontWeight: 'bold' }}
                    // gutterBottom
                >
                    Consultoria para Pequenas Empresas
                </Typography>

                <Typography
                    variant={width > md? 'h5': 'h6'}
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    sx={{ marginBottom: '2em', fontStyle: 'italic' }}
                    // gutterBottom
                >
                        Veja como a Nosso Consultor pode fazer a diferença nos resultados da sua empresa.
                        <br/>
                        Nossa expertise em pequenos negócios oferece estratégias personalizadas para transformar desafios em oportunidades.
                </Typography>

                <Box className={classes.video} >
                    <ReactPlayer
                        url='https://youtu.be/SGqCg2bnaDI'
                        controls
                        // light
                        width='100%'
                        height='100%'
                    />
                </Box>
                
            </Box>
        </Box>
    );

}