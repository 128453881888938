import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: '85%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },

    button: {
        padding: '0.7em',
        // marginInline: '2em',
        // marginTop: '3em',
        marginBottom: '1em',
        border: '1px solid',
        borderRadius: '1em!important',
        borderColor: theme.colors.light_blue,
        backgroundColor: theme.palette.primary.main,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            marginBottom: '0.1em',
            padding: '1.5em',
            paddingInline: '2em',
        },
    },

    author: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5em 1em 0.5em 1em',
        marginTop: '0.5em',

        borderRadius: '1em',
        border: '1px solid',
        borderColor: theme.colors.light_gray,
        // backgroundColor: theme.colors.light_gray,
    },

    warranties: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexDirection: 'column',

        padding: '1em 2em 1em 2em',

        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
        },
    },

    warranty: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.4em',
        paddingInline: '0.5em',

        [theme.breakpoints.up('sm')]: {
            paddingInline: '1.2em',
        },
    },

}));
export default useStyles;