import { makeStyles } from '@mui/styles';

import logo from '../../../assets/company/logo.svg';

const useStyles = makeStyles(theme => ({ 

    general: {
        width: '100%',
        // maxHeight: '80vh',
        // marginTop: '5em',

        backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

        // [theme.breakpoints.up('sm')]: {
        //     position: 'relative',
        // },
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '100%',
        // minHeight: '100vh',
        paddingTop: '3em',
        paddingBottom: '3em',
        paddingInline: '1em',
        background: 'rgba(255,255,255,0.9)',
        // background: `linear-gradient(${theme.colors.light_gray}, ${theme.colors.light_gray}, ${theme.colors.white})`,


        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },

    text_container: {
        margin: '2em',

        [theme.breakpoints.up('md')]: {
            margin: '2em 2em 2em 4em',
        },
    },

    form_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '95%',
        // minHeight: '100vh',
        // paddingTop: '5em',
        // paddingBottom: '1em',
        // paddingInline: '1em',
        padding: '1em',
        background: 'rgba(255,255,255,0.65)',

        border: '1px solid',
        borderColor: theme.colors.light_blue,
        borderRadius: '1em',

        [theme.breakpoints.up('md')]: {
            width: '50%',
            marginRight: '3em',
            minWidth: '500px',
        },
    },

    // form: {
    //     maxWidth: '500px',
    // },

    buttons: {
        marginTop: '1.5em',
        // marginBottom: '3em',
    }
    
}));
export default useStyles;