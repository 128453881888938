import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '2em',
        background: `linear-gradient(${theme.colors.light_gray}, ${theme.colors.white}, ${theme.colors.white}, ${theme.colors.white})`,
    },

    container: {
        maxWidth: '1200px',
        // padding: '1em 2em 2em 2em',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '2em',
        paddingBottom: '2em',
    },

    quote: {
        maxWidth: '800px',
        paddingInline: '1em',
    },

    case_container: {
        paddingTop: '0.5em',
        paddingBottom: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // flexWrap: 'wrap',
    },

    case: {
        width: '100%',
        maxWidth: '90vw',
        padding: '2em 2em 2em 2em',
        margin: '1em',

        borderRadius: '0.5em',
        border: '1px solid',
        borderColor: theme.colors.light_gray,
        background: `linear-gradient(${theme.colors.white},
            ${theme.colors.white}, ${theme.colors.white}, ${theme.colors.light_gray})`,
    
        [theme.breakpoints.up('md')]: {
            padding: '2em 5em 2em 5em',
        },
    },

    clients: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',

        maxWidth: '800px',
        // marginBottom: '1em',
    },

    // client_frame: {
    //     height: '80px',
    //     width: 'auto',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    // },

    client: {
        height: 'auto',
        maxHeight: '100px',
        maxWidth: '100px',
        margin: '1em 0.5em 1em 0.5em',
        // flexGrow: '1',
    },

    testimonial_video: {
        
        backgroundColor: theme.colors.light_gray,
        borderRadius: '1em',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        // padding: '2em 5em 2em 5em',
        // margin: '3em 1em 3em 1em',

        width: '95vw',
        padding: '1em 0 1em 0',
        margin: '2em 0 2em 0',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            width: 'auto',
            padding: '2em 5em 2em 5em',
            margin: '3em 1em 3em 1em',
        },
    },

    vid_desc: {
        // width: '50%',
        width: '300px',
        height: '100%',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',

        margin: '1em',

        order: 2,
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },

    video: {
        height: '75vh',
        maxHeight: '534px',

        width: '300px',
        // maxWidth: '300px',

        margin: '1em',
        
        boxShadow: '0 0.2em 0.4em 0 rgba(0, 0, 0, 0.2), 0 0.3em 1em 0 rgba(0, 0, 0, 0.19)',

        order: 1,
        [theme.breakpoints.up('sm')]: {
            order: 2,
        },
    },

 
    
}));
export default useStyles;