import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        width: '100%',
        maxWidth: '800px',
        // height: 'auto',
        // maxHeight: '600px',
        paddingTop: '0.5em',
        paddingBottom: '5em',
        marginLeft:'3%',
        marginRight:'3%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '3em',
        },
    },

    logo: {
        width: '150px',
        margin: '2em',
        [theme.breakpoints.up('sm')]: {
            width: '200px',
        },
    },

    block: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '100%',
        backgroundColor: theme.colors.light_gray,
        padding: '3em 1em 3em 1em',
        margin: '3em 0 0 0',

        // border: '1px solid',
        // borderColor: theme.colors.light_gray,
        borderRadius: '1em',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            // height: '80vh',
            padding: '5em 2em 5em 2em',
            margin: '5em 0 0 0',
        },
    },

    founder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // padding: '0 2em 0.5em 2em',
 
    },

    foto: {
        // width: '80%',
        maxWidth: '110px',
        height: 'auto',
        // marginBottom: '1em',
        borderRadius: '200px',

        [theme.breakpoints.up('sm')]: {
            // width: '50%',
            width: '35vw',
            // borderRadius: '10px',
        },
    },

    presentation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',

        paddingBottom: '1em',
    },

    presentation_text: {
        padding: '0 0 0 1em',
    },

    info: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
  
    },

    curriculum: {
        width: '90%',
        // height: '50px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingBottom: '0.5em',
        flexWrap: 'wrap',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },

    curriculum_logo: {
        width: '80%',
        maxWidth: '80px',
        height: 'auto',
        
        // filter: 'grayscale(100%)',
        
        margin: '0.5em',
        // margin: '1em 0.7em 0 0.7em',
        // margin: '0.7em',
        // [theme.breakpoints.up('sm')]: {
        //     width: '50%',
        //     width: '15vw',
        // },
    },

    nc_curriculum: {
        width: '150px',
        margin: '2em',
        [theme.breakpoints.up('sm')]: {
            // width: '200px',
            margin: '0.5em',
        },
    },
    
}));
export default useStyles;