import React from 'react';
import { Box, Typography } from '@mui/material';
import { DrawRounded, CategoryRounded, InsightsRounded } from '@mui/icons-material';

import { useTheme } from '@mui/styles';
import ReactPlayer from 'react-player';

import Pilar from './Pilar';
import Title from '../../../components/Title';
// import CTA from '../../../components/CTA';

import useStyles from './styles';

export default function Foundation() {

    const classes = useStyles();
    const theme = useTheme();

    const consulting_pilars = [
        {
            title: 'ESTRATÉGIA',
            description: 'QUAL DIREÇÃO SEGUIR?',
            project: 'Pesquisa e definição da estratégia empresarial.',
            itens: ['Plano de Negócios', 'Viabilidade Econômica', 'Jornada do Cliente', 'Precificação', '...'],
            icon: DrawRounded,
        },
        {
            title: 'PLANEJAMENTO',
            description: 'COMO VAMOS CHEGAR LÁ?',
            project: 'Definição de indicadores, metas e plano de ação.',
            itens: ['Gestão Financeira', 'Gerenciamento pelas Diretrizes', 'Desdobramento de Indicadores', 'Definição de Metas', '...'],
            icon: CategoryRounded,
        },
        {
            title: 'GESTÃO',
            description: 'VAMOS!',
            project: 'Garantir operação fluida no dia-a-dia.',
            itens: ['Metodologida PDCA', 'Gerenciamento da Rotina', 'Reestruturação de Processos', 'Checklist de Tarefas', '...'],
            icon: InsightsRounded,
        },
    ];


    return(
        <Box className={classes.general}>
            <Box className={classes.container}>

                <Title
                        main='TRÊS PILARES'
                        description='Entenda os 3 pilares que utilizamos em nossos projetos'
                >
                    <p>
                        Nossa consultoria oferece soluções baseadas em três pilares fundamentais: estratégia, planejamento e gestão.
                        Atuamos em todas as áreas da sua empresa, incluindo gestão financeira e estratégica, vendas, operações e atendimento ao cliente.
                        Os pilares definem a direção, traçam o caminho e garantem o acompanhamento constante para alcançar o sucesso sustentável da sua empresa.
                    </p>
                </Title>

                <Box display='flex' justifyContent='center' flexWrap='wrap' >
                    {consulting_pilars.map((e,i) => 
                        <Pilar 
                                key={i}
                                number={e.number}
                                title={e.title}
                                description={e.description}
                                project={e.project}
                                itens={e.itens}
                                Icon={e.icon}
                        />
                    )}
                </Box>

                <Typography
                    variant='h5'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ marginTop: '1.5em' }}
                    gutterBottom
                >
                    <p>Assista o vídeo e descubra como trabalhamos os <b>três pilares</b> em nossos projetos</p>
                </Typography>
                

                <Box className={classes.video} >
                    <ReactPlayer
                        url='https://youtu.be/Rt9HtzEqcSQ'
                        controls
                        // light
                        width='100%'
                        height='100%'
                    />
                </Box>
                
            </Box>
        </Box>
    );

}