import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { UndoRounded } from '@mui/icons-material';


import Footer from '../../components/Footer';
import Title from '../../components/Title';

import useStyles from './styles';


export default function ConfProposal() {
    
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    
 
    return(
        <Fragment>
            <Box className={classes.general}>
                <Box className={classes.container}>
                    
                    <Title
                            main='PARABÉNS!'
                            description='Esse é o primeiro passo para mudar o futuro do seu negócio.'
                    >
                        <p>
                            Recebi sua mensagem e logo entrarei em contato.
                        </p>
                        <p>
                            <b>Até mais!</b>
                        </p>
                    </Title>

                    <Button
                        variant='outlined'
                        endIcon={<UndoRounded />}
                        color='secondary'
                        onClick={() => navigate('/')}
                        // sx={{m: '0.3em'}}
                    >
                        Voltar
                    </Button>

                </Box>

            </Box>

            <Footer />
        </Fragment>
    );

}
