import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import big_company from '../../../assets/others/big_company.webp';
import small_company from '../../../assets/others/small_company.webp';

import useStyles from './styles';
import ViewPort from '../../../services/ViewPort';


export default function Argument() {
    
    const classes = useStyles();
    const theme = useTheme();
    
    const { width } = ViewPort();
    const sm = 600;
    
    return(
        <Box className={classes.general}>
            <Box className={classes.main}>
                <Typography
                    variant='h6'
                    component='h3'
                    align='center'
                    color='primary'
                    sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    CONSULTORIA PARA PEQUENAS EMPRESAS
                </Typography>
                <Typography
                    variant={width>sm?'h3':'h4'}
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    gutterBottom
                >
                    Por que contratar uma CONSULTORIA?
                </Typography>

                <Typography
                        variant='body1'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ fontStyle: 'italic', fontWeight: 'bold', pb: '0.5em', pt: '1em' }}
                        // gutterBottom
                >
                    GRANDES EMPRESAS
                </Typography>
                <img
                        className={classes.image}
                        src={big_company}
                        alt='grandes_empresas'
                />

                <Typography
                        variant='body1'
                        component='div'
                        align='left'
                        // gutterBottom
                >
                    <p>
                        Grandes empresas possuem <b>áreas especializadas</b>: marketing, comercial, financeiro, recursos humanos, operacional, etc.
                        E, para cada uma delas existe uma equipe com um <b>grande conhecimento específico</b> para entregar o melhor resultado para a companhia.
                    </p>
                </Typography>


                <Typography
                        variant='body1'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ fontStyle: 'italic', fontWeight: 'bold', pb: '0.5em', pt: '2em' }}
                        // gutterBottom
                >
                    PEQUENAS EMPRESAS
                </Typography>
                <img
                        className={classes.image}
                        src={small_company}
                        alt='pequenas_empresas'
                />

                <Typography
                    variant='body1'
                    component='div'
                    align='left'
                    // sx={{ m: 1 }}
                    // gutterBottom
                >
                    <p>
                        Em pequenas empresas, o <b>dono é responsável</b> tanto pela área operacional quanto pelas <b>áreas administrativas</b>,
                        muitas vezes <b>sem o conhecimento necessário ou tempo disponível</b>.
                    </p>
                    <p>
                        Ter um <b>consultor com conhecimento das áreas administrativas</b> vai ajudar o dono na administração do negócio,
                        trazendo todo <b>conhecimento de gestão</b> de empresas para o seu negócio.
                    </p>
                </Typography>

            </Box>
        </Box>
    );

}