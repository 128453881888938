import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import useStyles from './styles';

export default function Item(props) {

    const classes = useStyles();
    const theme = useTheme();

    const { phase, name, title } = props;

    return(

        <Box className={classes.general} >

            <Box className={classes.phase_box}>
                <Typography
                    variant='h5'
                    component='p'
                    align='center'
                    color='primary'
                    sx={{ fontWeight: 'bold' }}
                    // gutterBottom
                >
                    {phase}
                </Typography>
                <Typography
                    variant='h4'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ fontWeight: 'bold' }}
                    // gutterBottom
                >
                    {name}
                </Typography>
            </Box>

            <Box className={classes.text_box} >
                <Typography
                    variant='h6'
                    component='p'
                    align='left'
                    color={theme.colors.dark_gray}
                    sx={{ fontWeight: 'bold' }}
                    // gutterBottom
                >
                    {title}
                </Typography>

                <Typography
                    variant='body1'
                    component='div'
                    align='left'
                    color={theme.colors.dark_gray}
                    // sx={{ fontWeight: 'bold' }}
                    // gutterBottom
                >
                    {props.children}
                </Typography>
            </Box>
        </Box>

    );

}