import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: '100%',
        
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
        paddingTop: '5em',
        paddingBottom: '5em',

        background: theme.colors.white_ish,
    },

}));
export default useStyles;