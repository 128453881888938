import { makeStyles } from '@mui/styles';

import logo from '../../assets/company/logo.svg';

const useStyles = makeStyles(theme => ({ 

    general: {
        width: '100%',
        minHeight: '100vh',
        // marginTop: '5em',

        backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

        // [theme.breakpoints.up('sm')]: {
        //     position: 'relative',
        // },
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '100%',
        height: '100%',
        minHeight: '100vh',
        paddingTop: '4em',
        paddingBottom: '1em',
        paddingInline: '1em',
        background: 'rgba(255,255,255,0.9)',

        // [theme.breakpoints.up('sm')]: {
        //     position: 'absolute',
        // },
    },

    
}));
export default useStyles;