import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';


// ----------------- Importando  páginas -----------------

import PV from './pages/PV';
import ConfProposal from './pages/ConfProposal';


export default function RoutesNC() {

    return (
        <Routes>

            {/* ------------------ Link Direto ------------------ */}
            {/* <Route exact path='/' element={<PV05 />} /> */}


            {/* --------------- Redirecionamento ---------------- */}
            <Route exact path='/' element={<Navigate replace to='/pv' />} />
            

            {/* -------------------- Páginas -------------------- */}
            <Route path='/pv' element={<PV />}/>
            <Route path='/confirmation-proposal' element={<ConfProposal />}/>

            
            {/* se endereço errado, redireciona para / */}
            <Route path='*' element={<Navigate replace to='/' />} />

        </Routes>
    );
}   