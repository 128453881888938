import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AttachMoneyRounded, TrendingUpRounded, LabelImportantRounded,
        MoodRounded, TocRounded } from '@mui/icons-material';
import ReactPlayer from 'react-player';

import Title from '../../../components/Title';
// import CTA from '../../../components/CTA';
import ViewPort from '../../../services/ViewPort';

import patricia_bomfim_arquitetura from '../../../assets/clients/patricia_bomfim_arquitetura.webp';
import dog_school from '../../../assets/clients/dog_school.webp';
import cro from '../../../assets/clients/cro.webp';
import hayati from '../../../assets/clients/hayati.webp';
import bonfim_iluminacao from '../../../assets/clients/bonfim_iluminacao.webp';
import agil_telecom from '../../../assets/clients/agil_telecom.webp'
import casa_das_organistas from '../../../assets/clients/casa_das_organistas.webp'
import onix from '../../../assets/clients/onix.webp'
import innova_safety from '../../../assets/clients/innova_safety.webp'
import petshop_fino_trato from '../../../assets/clients/petshop_fino_trato.webp'
import larbor from '../../../assets/clients/larbor.webp'
import bonfim_eletrica from '../../../assets/clients/bonfim_eletrica.webp'
import houi_produtora from '../../../assets/clients/houi_produtora.webp'
import silo_gourmet from '../../../assets/clients/silo_gourmet.webp'
import godoy from '../../../assets/clients/godoy.webp'

import useStyles from './styles';

export default function Clients() {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600; 

    const clients_logo = [patricia_bomfim_arquitetura, dog_school, cro, bonfim_iluminacao, hayati,
                          agil_telecom, casa_das_organistas, onix, innova_safety, petshop_fino_trato,
                          larbor, bonfim_eletrica, houi_produtora, silo_gourmet, godoy ];  

    const cases = [
        {
            icon: AttachMoneyRounded,
            title: 'Aumentando Faturamento de Empresa de Serviços',
            description: <div>
                            <p>
                                Na Nosso Consultor, oferecemos a solução para um desafio comum: aumentar as vendas de forma consistente.
                                Muitas empresas enfrentam dificuldades em identificar as razões por trás do baixo desempenho, muitas
                                vezes adotando abordagens aleatórias. Nossa abordagem é diferente. Analisamos minuciosamente o processo
                                de captação de clientes, otimizando cada etapa do funil de vendas e focando nas oportunidades-chave para
                                aumentar o faturamento mensal de forma sustentável.
                            </p>
                            <p>
                                Com uma colaboração contínua e análise de resultados, ajudamos as empresas a atingirem seu potencial máximo de vendas.
                            </p>
                        </div>
        },{
            icon: TrendingUpRounded,
            title: 'Recuperação Financeira de Comércio Varejista',
            description: <div>
                            <p>
                                Muitas empresas enfrentam desafios financeiros por falta de acompanhamento e compreensão. A Nosso Consultor oferece
                                uma solução eficaz: ao criar relatórios financeiros colaborativos, desvendamos os mistérios das suas finanças.
                                Trabalhando juntos, analisamos detalhadamente os números, simulamos cenários para recuperação e definimos estratégias
                                sólidas.
                            </p>
                            <p>
                                Com resultados mensuráveis e controle financeiro restaurado, você estará pronto para um futuro de sucesso. Dê o
                                primeiro passo para uma transformação financeira, e junte-se a nós para moldar um futuro financeiro mais sólido e
                                promissor.
                            </p>
                        </div>
        
        },{
            icon: LabelImportantRounded,
            title: 'Revisão de Processos em Empresa de Serviços',
            description: <div> 
                            <p>
                                Para muitos proprietários de pequenas empresas, a sobrecarga de responsabilidades é uma realidade. A falta de pessoal
                                qualificado ou tempo para treinamento muitas vezes leva a um acúmulo de tarefas exclusivas do dono. Empoderar um
                                funcionário com uma tarefa crucial pode ser uma decisão desafiadora, com preocupações sobre desempenho e erros.
                            </p>
                            <p>
                                A abordagem da Nosso Consultor simplifica esse processo. Ao compreender minuciosamente as atividades e esboçar
                                processos detalhados, facilitamos a transição das responsabilidades. Acompanhamento regular, sem a necessidade de
                                complexidade, garante que o processo ocorra sem problemas. Este é o passo vital para uma delegação bem-sucedida,
                                permitindo que você direcione seu foco para o crescimento da empresa.
                            </p>
                        </div>
        },{
            icon: MoodRounded,
            title: 'Fidelizando Clientes em Consultório',
            description: <div>
                            <p>
                                Em setores altamente competitivos, a retenção de clientes é uma batalha constante. A busca pelo menor preço muitas vezes
                                obscurece o valor real dos serviços, tornando a fidelização um desafio.
                            </p>
                            <p>
                                A Nosso Consultor tem uma estratégia que se baseia na compreensão profunda do cliente. Através de entrevistas e colaboração
                                interna, identificamos maneiras de enriquecer o relacionamento e destacar o valor exclusivo de seus serviços. Ao abordar
                                a satisfação do cliente, testamos e ajustamos ideias, remodelando toda a comunicação, desde a pré-venda até o pós-venda.
                            </p>
                            <p>
                                Ao redirecionar o foco do preço para o valor agregado, você pode conquistar clientes leais e transformar a percepção de
                                seus serviços no mercado competitivo. Elevamos o seu negócio além do preço, construindo relacionamentos duradouros com
                                base no valor real entregue.
                            </p> 
                        </div>
        },{
            icon: TocRounded,
            title: 'E Muitos Outros...',
            description: <div>
                            <p>
                                A trajetória de 5 anos desde a inauguração da Nosso Consultor só está começando. Com cada novo projeto, nosso foco é mergulhar
                                profundamente nas necessidades exclusivas do seu negócio. Juntos, colaboraremos para identificar as melhores estratégias e
                                definir o percurso ideal para solucionar seus desafios.
                            </p>
                            <p>
                                Além dos casos apresentados, nosso histórico inclui projetos envolvendo aprimoramento financeiro, redução de despesas,
                                otimização de margens, reestruturação de rotinas e implementação de acompanhamento de resultados.
                            </p>
                            <p>
                                Nosso compromisso é impulsionar o crescimento e a eficiência de seu negócio.
                                Junte-se a nós para uma jornada de transformação empresarial.
                            </p>
                        </div>
        },
      
    ];

// 05) DEPOIMENTOS
// Em imagem, texto ou vídeo…
// Foto com texto?
// Patrícia -> triplicou o faturamento em 3 meses
// João Vitor -> redução do estoque em 20%
// …
// Precisa comprovar o sucesso, que deu certo
// Pegar variedade de coisas que eu resolvi… (tipos de clientes diferentes para eles se identificarem e eu mostrar que já fiz isso)


    return(
        <Box className={classes.general}>

            <Box className={classes.container}>
                
                <Title
                        main='PROJETOS E CLIENTES'
                        description='Veja o que a Nosso Consultor já fez'
                >
                    <p>
                        e também alguns dos clientes que passaram por aqui!
                    </p>
                </Title>

                <Box className={classes.case_container} >
                    {cases.map((c,i) => {
                        const Icon = c.icon;

                        return(
                            <Box key={i} className={classes.case}>

                                <Box display='flex'>
                                    {/* <Avatar sx={{ width: 70, height: 70, backgroundColor: theme.colors.gray }} > */}
                                    <Icon fontSize='large' color={theme.colors.white} />
                                    {/* </Avatar> */}

                                    <Typography
                                        variant='h5'
                                        component='h2'
                                        align='left'
                                        color={theme.colors.dark_gray}
                                        sx={{ fontWeight: 'bold', pl: '1em' }}
                                        gutterBottom
                                    >
                                        {c.title}
                                    </Typography>
                                </Box>

                                <Typography
                                    variant='body1'
                                    component='div'
                                    align='left'
                                    color={theme.colors.dark_gray}
                                    // sx={{ fontWeight: 'bold' }}
                                    gutterBottom
                                >
                                    {c.description}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>

                <Box className={classes.clients} >
                    {clients_logo.map((e,i) => 
                        <img className={classes.client} src={e} alt='clientes' key={i}/>
                    )}
                </Box>


                <Box className={classes.testimonial_video} >

                    <Box className={classes.vid_desc}>
                        <Typography
                                variant={width>sm? 'h6' : 'body1'}
                                component='div'
                                align={width>sm? 'right' : 'center'}
                                color={theme.colors.dark_gray}
                                sx={{ fontStyle: 'italic' }}
                        >
                            <p style={{ padding:0, margin: 0}}>
                                Neste vídeo, João Vitor compartilha sua <b>experiência transformadora</b> com a Nosso Consultor. 
                                Da gestão diária aos desafios estratégicos, ele detalha como nossa consultoria personalizada
                                foi essencial para o <b>crescimento e sucesso</b> do seu negócio.
                                A Nosso Consultor está comprometida em levar <b>conhecimento em gestão e administração a pequenos empresários</b>,
                                transformando desafios em oportunidades de crescimento. Este vídeo é um exemplo real do poder de uma consultoria eficaz.
                            </p>
                        </Typography>
                    </Box>

                    <Box className={classes.video} >
                        <ReactPlayer
                            url='https://youtube.com/shorts/yU8Hh2bokjw '
                            controls
                            // light
                            width='100%'
                            height='100%'
                        />
                    </Box>

                </Box>

                <Box className={classes.quote}>
                    <Typography
                            variant='h6'
                            component='div'
                            align='center'
                            sx={{ fontStyle: 'italic' }}
                            gutterBottom
                    >
                        <p style={{ padding:0, margin: 0}}>"Atingir os objetivos requer um trabalho em conjunto, consultor e cliente.
                        Ao investigar os problemas detalhadamente podemos focar nas atividades corretas.
                        Assim o resultado sempre aparece."</p>
                    </Typography>
                    <Typography
                            variant='h6'
                            component='p'
                            align='center'
                            color={theme.colors.dark_gray}
                            sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
                    >
                        - Alex Watanabe -
                    </Typography>
                </Box>

            </Box>
            
            {/*
            03) BOTÃO DE VENDAS
            CTA com impacto emocional
            Se você quer ter um negócio digital que vende muito todo santo dia e te proporciona um estilo de vida com conforto e segurança clique no link abaixo e entre no VTSD agora.
            Dê o primeiro passo para organizar sua empresa e sair do sufoco…
            [Entrar no VTSD]
            Pensar em colocar ou não o preço (como é mais barato, talvez faça sentido colocar o preço aqui) Dividir em pacotes de 3 meses, 6 meses?
            */}
            {/* <CTA
                main='ENTRAR EM CONTATO'
                secondary='ENTRAR EM CONTATO'
                text_value='Agende uma conversa gratuita'
                author={true}
                warranties={[
                    'Valor acessível para pequenas empresas',
                    'Cancele quando quiser sem multas',
                    'Consultoria individual',
                ]}
                href='/proposal'
            >
                <p style={{ padding:0, margin: 0}}>
                    Vamos conversar e analisar o seu caso <u>juntos</u>! Assim poderemos construir um projeto
                    à quatro mãos, que melhor se adeque a <u>sua realidade e empresa</u>.
                </p>
            </CTA> */}

        </Box>
    );

}