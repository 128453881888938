import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        padding: '2em',

        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },

    
    phase_box: {
        width: '100%',
        height: '180px',

        // border: '1px solid',
        borderRadius: '1em',
        // padding: '2em',
        margin: '0',
        
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        
        background: theme.colors.white,

        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            width: '300px',
            minWidth: '250px',
            margin: '0 3em 0 3em',
        },
    },
    
    text_box: {
        maxWidth: '700px',
        marginTop: '2em',

        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    }

}));
export default useStyles;