import { useState, useEffect } from 'react';

export default function ViewPort(props) {

    // xs = 0;
    // sm = 600;
    // md = 960;
    // lg = 1280;
    // xl = 1920;

    // estado
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return({ width });

}