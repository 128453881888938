import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    container: {
        maxWidth: '1200px',
        paddingTop: '1em',
        paddingBottom: '2em',
    },

}));
export default useStyles;