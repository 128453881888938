import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        // background: `linear-gradient(${theme.colors.light_gray}, ${theme.colors.light_gray}, ${theme.colors.white})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    container: {
        // maxWidth: '1200px',
        padding: '3em 1em 3em 1em',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            padding: '3em',
        },
    },
    
}));
export default useStyles;