import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { DoneRounded, TuneRounded, TrendingUpRounded, AccountTreeRounded } from '@mui/icons-material';

import online_meeting from '../../../assets/others/online_meeting.webp';

import Title from '../../../components/Title';
import CTAScroll from '../../../components/CTAScroll';

import Item from './Item';
import ViewPort from '../../../services/ViewPort';

import useStyles from './styles';


export default function Stage(props) {

    const classes = useStyles();
    const theme = useTheme();

    const { width } = ViewPort();
    const sm = 600;

    const stage = [
        {
            icon: TuneRounded,
            title: 'CONTENÇÃO',
            subtitle: 'BUSCA DE GANHOS RÁPIDOS',
            desc: <div>
                <p>
                    Empresa está com problemas mais agudos e precisa que sejam solucionados de forma rápida.
                </p>
                <p>
                    Para conseguir ganhos rápidos (<i>Quick Win</i>) faremos um diagnóstico <i>express</i> e construiremos juntos um plano
                    para resolução dos problemas prioritários da sua empresa.
                </p>
                <p>
                    <i><u>
                        Resolver os problemas mais urgentes do seu negócio
                    </u></i>
                </p>
            </div>,
        },
        {
            icon: AccountTreeRounded,
            title: 'CONTROLE',
            subtitle: 'ESTRUTURAÇÃO DA GESTÃO',
            desc: <div>
                <p>
                    Uma empresa organizada possui os metas e indicadores bem definidos. Além de uma
                    metodologia de acompanhammento e discussão de resultados.
                </p>
                <p>    
                    Aqui vamos estruturar a gestão da sua empresa definindo os indicadores (<i>KPIs</i>) e as metas.
                    Além disso, definiremos responsáveis e organizaremos as reuniões de acompanhamento de resultados
                    e revisão dos planos de ação.
                </p>
                <p>
                    <i><u>
                        Implantar metodologia de acompanhamento de resultados
                    </u></i>
                </p>
            </div>,
        },
        {
            icon: TrendingUpRounded,
            title: 'CRESCIMENTO',
            subtitle: 'FORTALECIMENTO DA GESTÃO',
            desc: <div>
                <p>
                    Depois de uma metodologia de gestão e acompanhamento bem implantada e funcionando, é hora de buscar voos
                    mais altos e planejar a expansão da sua empresa.
                </p>
                <p>
                    Nessa fase vamos discutir e definir estratégias de crescimento para o seu negócio, aprofundando as análises
                    e montando planos de ação cada vez mais detalhados.
                </p>
                <p>
                    <i><u>
                        Expandir o seu negócio e aumentar o lucro
                    </u></i>
                </p>
            </div>,
        },
    ];

    const bullet_points = [
        'Projetos personalizados',
        'Soluções práticas',
        'Diagnóstico empresarial completo',
        'Recomendações estratégicas',
        'Acompanhamento de indicadores',
        'Reuniões individuais',
        'Suporte entre as reuniões',
    ];
    

    return(
        <Box className={classes.general}>

            <Box className={classes.title}>
                <Title
                        main='CONSULTORIA EMPRESARIAL'
                        description='Apoio especializado em cada estágio do seu negócio'
                >
                    <p>
                        Toda empresa, seja ela pequena, média ou grande, atravessa três estágios: contenção, controle e crescimento.
                        Cada um desses estágios exige um tipo de abordagem diferente. Não importa em qual estágio sua empresa se encontra agora,
                        trabalharemos com o foco certo para garantir que ela prospere e alcance seus objetivos.
                    </p>
                </Title>
                
            </Box>

            <Box className={classes.stages} >
                {stage.map((e,i) => 
                    <Item
                        key={i}
                        Icon={e.icon}
                        title={e.title}
                        subtitle={e.subtitle}
                        desc={e.desc}
                    />
                )}
            </Box>

            <Box className={classes.details} >

                <Box className={classes.photo_container}>
                        <img
                            className={classes.photo}
                            src={online_meeting}
                            alt='reunioes_online'
                        />
                </Box>
                <Box className={classes.text}>
                    <Typography
                        variant='h6'
                        component='div'
                        align={width>sm? 'left' : 'center'}
                        color={theme.colors.middle_gray}
                        gutterBottom
                    >
                        <div>
                            <p style={{ margin: 0, padding: 0 }}>
                                A consultoria é oferecida tanto <b>online</b> quanto <b>presencialmente</b>, garantindo flexibilidade e
                                conveniência para atender às necessidades de cada negócio.
                            </p>
                            <ul style={{ marginTop: '0.2em', marginBottom: '0' }}>
                            {/* <ul> */}
                                <li>Diagnóstico completo da sua empresa</li>
                                <li>Definição de indicadores e metas</li>
                                <li>Planejamento e implantação modelo de gestão</li>
                                <li>Acompanhamento das atividades</li>
                            </ul>
                        </div>
                    </Typography>
                </Box>

            </Box>

            <Box className={classes.bullet_points}>
                {bullet_points.map((a, i) => 
                    <Box className={classes.bullet_points_details} key={i}>
                        <DoneRounded color='secondary' style={{ marginInline: '0.2em' }} />
                        <Typography
                            variant='body1'
                            component='p'
                            align='left'
                            noWrap={false}
                            color={theme.colors.middle_gray}
                        >
                            {a}
                        </Typography>
                    </Box>
                )}
            </Box>

            {/* <Typography
                    variant='body1'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ padding: width>sm? '2.5em 8em 1em 8em' : '1em 0.2em 1em 0.2em' }}
                    // gutterBottom
            >
                <p>
                    Um <u>diagnóstico especializado e preciso</u> faz toda a diferença no crescimento e na estabilidade do seu negócio.<br />
                    Vamos trabalhar a quatro mãos para identificar os <u>verdadeiros desafios</u> e encontrar as <u>melhores soluções</u> para sua empresa.
                </p>
            </Typography>
             */}
            <CTAScroll
                main='SOLICITE UM ORÇAMENTO'
                secondary='SOLICITE UM ORÇAMENTO'
                text_value='E veja como podemos te ajudar'
                author={true}
                warranties={[
                    '15 anos de experiência em consultoria empresarial',
                    'Soluções práticas para a realidade do seu negócio',
                    'Consultoria personalizada e individual',
                ]}
                func={props.scrollToProposal}
            >
                {/* <p style={{ padding:0, margin: 0}}>
                    Vamos conversar e analisar o seu caso <u>juntos</u>! Assim poderemos construir um projeto
                    à quatro mãos, que melhor se adeque a <u>sua realidade e empresa</u>.
                </p> */}
            </CTAScroll>

        </Box>
    );

}