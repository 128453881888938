import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: '95%',
        // position: 'relative',
        margin: '1em',
        border: '1px solid',
        borderRadius: '1em!important',
        borderColor: theme.colors.light_blue,

        [theme.breakpoints.up('sm')]: {
            // height: '300px',
            width: '370px',
        },
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.8em 1em 0.8em 0.5em',
    },

    number: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        width: '30px',
        height: '30px',
        marginRight: '0.5em',
    },

}));
export default useStyles;