import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    
    general: {
        background: theme.colors.white,
        // background: `linear-gradient(${theme.colors.white}, ${theme.colors.white_ish})`,

        paddingTop: '3em',
        paddingBottom: '3em',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        // [theme.breakpoints.up('sm')]: {
        //     paddingBottom: '3em',
        // },
    },

    container: {
        maxWidth: '1200px',
        padding: '1em 2em 2em 2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    others_qualities: {
        width: '100%',
        maxWidth: '1200px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexWrap: 'wrap',
        flexDirection: 'column',

        // marginTop: '2em',
        padding: '2em 1em 2em 1em',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            // marginTop: '5em',
            // paddingTop: '5em',
            // paddingBottom: '5em',
        },
    },

    other_quality: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.5em',
        paddingInline: '0.5em',

        [theme.breakpoints.up('sm')]: {
            paddingInline: '1.2em',
        },
    },

}));
export default useStyles;