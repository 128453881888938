import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import Title from '../../../components/Title';
import ViewPort from '../../../services/ViewPort';
// import CTAScroll from '../../../components/CTAScroll';

import Item from './Item';

import useStyles from './styles';

export default function Solutions() {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    return(
        <Box className={classes.general} >

            <Title
                    main='SOLUÇÕES PRESONALIZADAS'
                    description='Estratégias sob medida para empresas de comércio e serviço'
            >
                <p style={{ padding: 0, margin: 0 }}>
                    Na Nosso Consultor compreendemos as particularidades e desafios que cada setor enfrenta.
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                    Oferecemos soluções estratégicas e personalizadas tanto para empresas de varejo quanto para prestadores de serviços,
                    ajudando a otimizar operações, aumentar as vendas e melhorar a gestão financeira.
                </p>
            </Title>

            <Item
                phase='COMÉRCIO'
                name='VAREJISTA'
                title='Estratégias eficazes para seu comércio'
            >
                <p>
                    Entendemos os desafios únicos enfrentados pelas empresas de varejo. Nossa consultoria se dedica a criar soluções sob medida que atendam às
                    suas necessidades específicas. Com um foco detalhado em análise de margem, diagnóstico de estoque, e gestão financeira, oferecemos uma abordagem
                    completa para transformar seu negócio e garantir resultados sustentáveis.
                </p>
                <ul>
                    <li><u>Análise de Margem por Categoria e Produto</u>: Avaliação detalhada das margens de lucro para identificar oportunidades de melhoria;</li>
                    <li><u>Diagnóstico da Qualidade do Estoque</u>: Avaliamos o giro de estoque identificando produtos parados ou com risco de falta, garantindo equilíbrio entre oferta e demanda;</li>
                    <li><u>Precificação de Produtos</u>: Desenvolvimento de estratégias de preços competitivos para maximizar os lucros;</li>
                    <li><u>Avaliação do Ciclo Financeir</u>:: Identificação de oportunidades e desalinhamentos entre os prazos de recebimento de vendas e pagamentos a fornecedores, otimizando o fluxo de caixa da empresa;</li>
                    <li><u>Cálculo da Necessidade de Capital de Giro</u>: Determinação dos recursos necessários para manter a operação;</li>
                    <li><u>Diagnóstico Financeiro</u>: Avaliação completa da saúde financeira da empresa;</li>
                    <li><u>Potencializar Base Atual de Clientes</u>: Estratégias para aumentar a fidelidade e o valor do cliente existente;</li>
                    <li>E outas soluções personalizadas de acordo com as necessidades do seu negócio.</li>
                </ul>
            </Item>

            <Item
                phase='PRESTADORES DE'
                name='SERVIÇO'
                title='Estratégias para otimizar seu negócio'
            >
                <p>
                    Os prestadores de serviços precisam de abordagens específicas para gerir suas operações e expandir seus negócios.
                    Oferecemos soluções que abrangem desde a montagem de relatórios financeiros até a excelência no atendimento ao cliente.
                    Descubra como podemos transformar sua operação e impulsionar seu crescimento.
                </p>
                <ul>
                    <li><u>Diagnóstico Financeiro</u>: Análise detalhada das finanças para identificar áreas de melhoria;</li>
                    <li><u>Montagem de Relatórios e Rotina de Acompanhamento</u>: Criação de ferramentas para monitorar o desempenho financeiro regularmente;</li>
                    <li><u>Precificação dos Serviços</u>: Desenvolvimento de estratégias de preços que reflitam o valor dos serviços prestados;</li>
                    <li><u>Revisão e Desenvolvimento de Canais de Vendas</u>: Otimização e criação de novos canais para aumentar as vendas;</li>
                    <li><u>Excelência no Atendimento ao Cliente</u>: Melhoria do atendimento para aumentar a satisfação e fidelidade do cliente;</li>
                    <li><u>Criação do Perfil do Cliente</u>: Identificação e segmentação do público-alvo para estratégias mais eficazes;</li>
                    <li><u>Definição da Proposta de Valor</u>: Clarificação do valor único oferecido pelo seu serviço para se destacar no mercado;</li>
                    <li>E outras soluções personalizadas para atender às necessidades do seu negócio.</li>

                </ul>
            </Item>

            <Typography
                variant='h6'
                component='p'
                align='center'
                color={theme.colors.dark_gray}
                sx={{ padding: width>sm? '3em 10em 1em 10em' : '1em' , fontStyle: 'italic' }}
                // gutterBottom
            >
                <p style={{ padding: 0, margin: 0 }}>
                    Trabalhamos lado a lado com você para desenvolver estratégias que atendam às suas necessidades específicas,
                    garantindo clareza nos resultados e identificando oportunidades de crescimento.
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                    Com nossa abordagem personalizada, você terá a confiança e o suporte necessário para superar desafios e alcançar seus objetivos empresariais.
                </p>
            </Typography>

        </Box>
    );

}