import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// import ReactGA from 'react-ga';

import RoutesNC from './routesNC';
import theme from './styles/Themes'


function App() {

    // reportar page view no google analytics
    // useEffect(() => {
    //     ReactGA.initialize('UA-215700536-1');
    // }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RoutesNC />
        </ThemeProvider>
    );
}

export default App;