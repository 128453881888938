// “Sempre que fechar o mês e não sobrar dinheiro, você vai lembrar de fazer…”
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        // background: `linear-gradient(${theme.colors.light_gray}, ${theme.colors.white})`,
        background: `linear-gradient(${theme.colors.white}, ${theme.colors.light_gray})`,
        // background: theme.colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '5em',
    },
    
    title: {
        maxWidth: '1800px',
        // paddingTop: '1em',
        // paddingBottom: '1em',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            // marginTop: '2em',
            // marginBottom: '2em',
            paddingInline: '5em',
        }
    },

    stages: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexWrap: 'wrap',
    },

    details: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        // marginTop: '1.5em',
        marginBottom: '1.5em',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginTop: '2.5em',
            marginBottom: '2.5em',
        }
    },

    photo_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1em',

        [theme.breakpoints.up('sm')]: {
            marginBottom: '0',
        }
    },
    
    photo: {
        width: '100%',
        maxWidth: '600px',
        height: 'auto',
        borderRadius: '20px',
    },

    text: {
        width: '100%',
        maxWidth: '600px',
        paddingInline: '2em',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            alignItems: 'start',
        }
    },

    bullet_points: {
        width: '100%',
        maxWidth: '1000px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexWrap: 'wrap',
        flexDirection: 'column',

        // marginTop: '2em',
        padding: '2em 1em 2em 1em',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            // marginTop: '5em',
            // paddingTop: '5em',
            // paddingBottom: '5em',
        },
    },

    bullet_points_details: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.5em',
        paddingInline: '0.5em',

        [theme.breakpoints.up('sm')]: {
            paddingInline: '1.2em',
        },
    },

}));
export default useStyles;