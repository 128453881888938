import React from 'react';
import { useTheme } from '@mui/styles';
import { Typography, Box } from '@mui/material';

import ViewPort from '../../services/ViewPort';
import useStyles from './styles';


export default function Title(props) {

    const classes = useStyles();
    const theme = useTheme();

    const { width } = ViewPort();
    const sm = 600;

    const { main, description, side, nopadding } = props;

    return (
        <Box className={classes.general} >

            <Box className={classes.container} sx={{
                                                    ml: (width>sm & !nopadding)? '10%':'2em',
                                                    mr: (width>sm & !nopadding)? '10%':'2em',
                                                }}>
                <Typography
                    variant='h5'
                    component='h3'
                    align={side? side:'center'}
                    color='primary'
                    sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    {main}
                </Typography>
                <Typography
                    variant='h3'
                    component='p'
                    align={side? side:'center'}
                    color={theme.colors.dark_gray}
                    sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    {description}
                </Typography>
                <Typography
                    variant='h6'
                    component='div'
                    align={side? side:'center'}
                    color={theme.colors.middle_gray}
                    // sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    {props.children}
                </Typography>
            </Box>
        </Box>
    );
}