import React from 'react';
import { Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import nome from '../../assets/company/nome.svg';
import instagram from '../../assets/icons/instagram.svg';

import ViewPort from '../../services/ViewPort';

import useStyles from './styles';


export default function Footer(props) {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600; 

    //CNPJ, email

    return (
        <footer className={classes.general}>

            <img className={classes.logo} src={nome} alt='nosso_consultor' />
            <Typography
                    variant='body2'
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
                    gutterBottom
            >
                Juntos, sua empresa pode ir mais longe
            </Typography>

            <Link 
                className={classes.instagram} 
                target='_blank'
                rel='noreferrer'
                href='https://www.instagram.com/nossoconsultor/'
                underline='hover'
            >
                <img className={classes.instagram_image} src={instagram} alt='instagram_nosso_consultor' />
                <Typography
                    variant='body2'
                    component='p'
                    align='left'
                    color={theme.colors.dark_gray}
                    // sx={{ fontStyle: 'italic', fontWeight: 'bold', mb: '1.5em' }}
                    // gutterBottom
                >
                    nossoconsultor
                </Typography>

            </Link>

            <Typography
                    variant='caption'
                    component='div'
                    align='center'
                    color={theme.colors.middle_gray}
                    // sx={{ fontStyle: 'italic' }}
                    // gutterBottom
            >
                <div>
                    <p style={{ padding: 0, margin: 0 }}>
                        CNPJ: 31.258.422/0001-95
                    </p>
                    <p style={{ padding: 0, margin: 0 }}>
                        WhatsApp: (11) 3280-0204
                    </p>
                    <p style={{ padding: 0, margin: 0 }}>
                        alex.watanabe@nossoconsultor.com
                    </p>
                </div>
            </Typography>
        </footer>
    );
}