import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: `linear-gradient(${theme.colors.light_gray}, ${theme.colors.white})`,
        background: theme.colors.white_ish,
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        width: '95%',
        maxWidth: '1500px',
        
        minHeight: '90vh',
        
        paddingTop: '4em',
        paddingBottom: '4em',

        [theme.breakpoints.up('sm')]: {
            minHeight: '95vh',
            paddingBottom: '7em',
        },
    },

    logo: {
        width: '150px',
        alignSelf: 'center',
        margin: '5em 0 3em 0',

        [theme.breakpoints.up('sm')]: {
            width: '200px',
        },
    },
    
    buttons_container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        // margin: '0',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            // width: '100vw',
        },
    },

    buttons_bundle: {
        marginInline: '1em',
        marginBottom: '0.8em',
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
    },

    video: {
        height: '197px',
        width: '350px',

        boxShadow: '0 0.2em 0.4em 0 rgba(100, 100, 100, 0.2), 0 0.3em 1em 0 rgba(100, 100, 100, 0.19)',

        [theme.breakpoints.up('sm')]: {
            height: '400px',
            width: '71px',

            [theme.breakpoints.up('md')]: {
                height: '500px',
                width: '890px',
            },
        },
    },
    
}));
export default useStyles;